<template>
  <div class="page-content">
    <div class="whats-new mdl-card mdl-js-button shadow">
      <div class="close-button" @click="close">
        <img src="/images/icons/x.png" class="normal-mode close-icon" alt="close-button" />
        <img src="/images/icons/x-dark-mode.png" class="dark-mode close-icon" alt="close-button" />
      </div>
      <div class="head">
        <h1 class="title dark-grey">New on Pulse SMS Web</h1>
        <p class="subtitle">Included in your subscription</p>
      </div>
      <div class="body">
        <div class="feature" v-for="feature in features">
          <div class="icon">
            <img :src="`/images/whats-new/${feature.icon}.png`" alt="icon" />
          </div>
          <div class="content">
            <h2 class="dark-grey title">{{ feature.title }}</h2>
            <p class="light-grey description">{{ feature.description }}</p>
          </div>
        </div>
      </div>
      <div class="footer">
        <span @click="close" class="continue-button">GO TO MESSAGES</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from '@/utils';
export default {
  name: 'WhatsNew',
  data() {
    return {
      features: [
        {
          icon: 'settings',
          title: 'Advanced Settings',
          description: 'Set passcodes, auto-replies, automatic cleanups & more'
        },
        {
          icon: 'multi-select',
          title: 'Multi-Select Conversations',
          description: 'Manage multiple conversations in one tap'
        },
        {
          icon: 'templates',
          title: 'Manage Templates',
          description: 'Select and reformat your message template'
        },
        {
          icon: 'calendar',
          title: 'Organize Scheduled Messages',
          description: 'Automatically sorted by scheduled date'
        },
        {
          icon: 'multi-device',
          title: 'Automatic Syncing',
          description: 'Your preferences will now auto-sync between web and mobile'
        }
      ]
    };
  },
  mounted() {
    this.$store.commit('loading', false);
    this.$store.commit('title', 'What\'s New');
    Api.account.settings.update('whats_new_promo_shown', 'boolean', 'true');
  },
  methods: {
    close() {
      this.$router.push({ name: 'conversations-list' });
    }
  }
};
</script>

<style scoped>
.page-content {
  margin-top: 46px;
  padding-top: 0 !important;
}

.shadow {
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
}

.close-button {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.close-icon {
  width: 20px;
  .dark & {
    display: none;
  }
}

.close-icon.dark-mode {
  display: none;
  .dark & {
    display: inline-block;
  }
}

.whats-new {
  width: 100%;
  padding: 32px 15px;
  .dark & {
    background: inherit;
  }
}

.whats-new .head {
  text-align: center;
}

.whats-new .head .title {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 4px;
}

.whats-new .head .subtitle {
  display: inline;
  padding: 2px 8px;
  margin: 0;
  border-radius: 4px;
  background: linear-gradient(180deg, #1775D2 0%, #1775D2 100%);
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.whats-new .body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 479px;
  margin: 32px auto;
}

.whats-new .body .feature {
  display: flex;
  align-items: center;
  gap: 14px;
}

.whats-new .body .feature .icon img {
  width: 28px;
}

.whats-new .body .feature .content .title {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.whats-new .body .feature .content .description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.whats-new .footer {
  text-align: center;
}

.whats-new .footer .continue-button {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  .dark & {
    color: #FFF;
  }
}

.dark-grey {
  color: #3F3F3F;
  .dark & {
    color: #FFF;
  }
}

.light-grey {
  color: #878787;
  .dark & {
    color: #B6B6B6;
  }
}
</style>
