import axios from 'axios';
import { Api, Url, Crypto, SessionCache } from '@/utils/';

export default class AutoReply {
    static get () {
        const constructedURL = Url.get('auto_replies') + Url.getAccountParam();
        const promise = new Promise((resolve, reject) => {
            if (!SessionCache.hasAutoReplies()) {
                Api.fetchResourcesInChunks(constructedURL, 100, Crypto.decryptAutoReply)
                    .then((response) => {
                        SessionCache.putAutoReplies(response);
                        resolve(response);
                    })
                    .catch(reject);
            } else {
                resolve(SessionCache.getAutoReplies());
            }
        });
        return promise;
    }

    static create (reply_type, pattern, response) {
        const auto_reply = {
            device_id: Api.generateId(),
            pattern: pattern,
            response: response,
            reply_type: reply_type
        };
        const request = {
            auto_replies: [{
                ...auto_reply,
                pattern: Crypto.encrypt(pattern),
                response: Crypto.encrypt(response),
            }]
        };
        const constructed_url = Url.get('create_auto_reply') + Url.getAccountParam();
        const promise = new Promise((resolve) => {
            axios.post(constructed_url, request, { 'Content-Type': 'application/json' })
                .then(response => {
                    SessionCache.addAutoReply(auto_reply);
                    resolve(response) 
                });
        });
        return promise;
    }

    static delete(id) {
        let constructed_url = Url.get('remove_auto_reply') + id + Url.getAccountParam();
        const promise = new Promise((resolve, reject) => {
            axios.post(constructed_url)
                .then((response) => {
                    SessionCache.deleteAutoReply(id);
                    resolve(response);
                })
                .catch(reject);
        });
        return promise;
    }
}
