<template>
    <div id="contact-list" class="page-content">
        <!-- Spinner On load -->
        <spinner v-if="contacts.length == 0" class="spinner" />

        <!-- Conversation items -->
        <transition-group name="flip-list" tag="div">
            <component :is="'ContactItem'" v-for="contact in contacts" :key="contact.hash" :contact-data="contact" />
        </transition-group>
    </div>
</template>

<script>

import Hash from 'object-hash';
import { Api, i18n, Util, SessionCache } from '@/utils';
import ContactItem from './ContactItem.vue';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'Contacts',

    components: {
        ContactItem,
        Spinner
    },

    data () {
        return {
            title: 'Contacts',
            contacts: []
        };
    },

    mounted () {
        this.$store.state.msgbus.$on('refresh-btn', this.refresh);
        this.$store.state.msgbus.$on('addedContact', this.contactAdded);
        this.$store.state.msgbus.$on('updatedContact', this.contactUpdated);
        this.$store.state.msgbus.$on('deletedContactsByPhoneNumbers', this.contactsDeletedByPhoneNumbers);
        this.$store.state.msgbus.$on('deletedContactsByIds', this.contactsDeletedByIDs);

        this.fetchContacts();

        // Construct colors object from saved global theme
        const colors = {
            default: this.$store.state.theme_global_default,
            dark: this.$store.state.theme_global_dark,
            accent: this.$store.state.theme_global_accent
        };

        // Commit them to current application colors
        this.$store.commit('colors', colors);
    },

    beforeDestroy () {
        this.$store.state.msgbus.$off('refresh-btn', this.refresh);
        this.$store.state.msgbus.$off('addedContact', this.contactAdded);
        this.$store.state.msgbus.$off('updatedContact', this.contactUpdated);
        this.$store.state.msgbus.$off('deletedContactsByPhoneNumbers', this.contactsDeletedByPhoneNumbers);
        this.$store.state.msgbus.$off('deletedContactsByIds', this.contactsDeletedByIDs);
    },

    methods: {

        contactAdded (deviceID) {
            this.fetchContacts();
        },

        contactUpdated (deviceID) {
            this.fetchContacts();
        },

        contactsDeletedByPhoneNumbers (phoneNumbers) {
            this.fetchContacts();
        },

        contactsDeletedByIDs (ids) {
            this.fetchContacts();
        },

        fetchContacts (clearCache = false) {
            if (clearCache) {
                Util.snackbar(i18n.t('compose.downloading'));
                SessionCache.invalidateContacts();
            }
            Api.contacts.get()
                .then(response => this.processContacts(response));
        },

        processContacts (response) {
            const renderList = [];

            for(let i = 0; i < response.length; i++) {
                const item = response[i];
                item.hash = Hash(item);

                renderList.push(item);
            }

            this.contacts = renderList;

            this.$store.commit("loading", false);
            this.$store.commit('title', this.title);
        },

        refresh () {
            this.fetchContacts(true);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    @import "../../../assets/scss/_vars.scss";

    #contact-list {
        width: 100%;

        .spinner {
            margin-top: 100px;
        }
    }

    .flip-list-enter, .flip-list-leave-to	{
        opacity: 0;
    }

    .flip-list-leave-active {
        position: absolute;
    }

    .flip-list-move {
        transition: transform $anim-time;
    }
</style>
