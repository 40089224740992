import axios from 'axios';
import { Api, Url, Crypto, SessionCache } from '@/utils/';

export default class Templates {
    static get () {
        const constructedURL = Url.get('templates') + Url.getAccountParam();
        const promise = new Promise((resolve, reject) => {
            if (!SessionCache.hasTemplates()) {
                Api.fetchResourcesInChunks(constructedURL, 100, Crypto.decryptTemplate).then((templates) => {
                    SessionCache.putTemplates(templates);
                    resolve(templates);
                }).catch(reject);
            } else {
                resolve(SessionCache.getTemplates());
            }
        });
        return promise;
    }

    static delete (id) {
        const constructed_url = Url.get('remove_template') + id + Url.getAccountParam();
        axios.post(constructed_url);
    }

    static create (text) {
        const request = {
            templates: [
                {
                    device_id: Api.generateId(),
                    text: Crypto.encrypt(text)
                }
            ]
        };

        const constructed_url = Url.get('create_template') + Url.getAccountParam();

        const promise = new Promise((resolve) => {
            axios.post(constructed_url, request, { 'Content-Type': 'application/json' })
                .then(response => { resolve(response) });
        });

        return promise;
    }

    static update (id, text) {
        // eslint-disable-next-line camelcase
        const encryptedText = Crypto.encrypt(text);
        const constructed_url = Url.get('update_template') + id + Url.getAccountParam();
        const promise = new Promise((resolve) => {
            axios.post(constructed_url, { text: encryptedText })
                .then(response => { resolve(response) });
        });
        return promise;
    }
}
