<template>
    <div id="create-passcode-pane" v-mdl class="mdl-card shadow">
        <div class="mdl-card__title">
            <h2 class="mdl-card__title-text">
                {{ $t('passcode.create') }}
            </h2>
        </div>
        <div class="mdl-card__supporting-text">
            <form>
                <div class="mdl-textfield mdl-js-textfield">
                    <input id="new-passcode" v-model="newPasscode" class="mdl-textfield__input" type="password" autofocus @keydown.enter.prevent.stop.exact="savePasscode" maxlength="4">
                    <label class="mdl-textfield__label" for="new-passcode">{{ $t('passcode.new_passcode') }}</label>
                </div>
            </form>
        </div>
        <div class="mdl-card__actions mdl-card--border">
            <button id="save" class="mdl-button mdl-js-button mdl-js-ripple-effect" @click="savePasscode">
                {{ $t('dialog.save') }}
            </button>
            <button id="cancel" class="mdl-button mdl-js-button mdl-js-ripple-effect" @click="cancelClicked">
                {{ $t('dialog.cancel') }}
            </button>
        </div>
        <transition name="loading-fade">
            <div v-if="loading" class="loading-center">
                <spinner />
            </div>
        </transition>
    </div>
</template>

<script>
import { Crypto, Util, Api } from '@/utils/';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'CreatePasscode',

    props: {
        encrypted_passcode: {
            type: String,
            default: null
        }
    },

    components: {
        Spinner
    },

    data () {
        return {
            title: '',
            newPasscode: '',
            loading: true
        };
    },

    mounted () {
        // Checking if user is allowed to create a new passcode.
        // If not, then redirecting to the settings page.
        // If user is allowed to create a new passcode, then rendering the page.
        // User is allowed to create a new passcode if:
        // 1. User doesn't have a passcode saved already
        // 2. User has a passcode saved already and /passcode/edit/:encrypted_passcode is being called and the encrypted_passcode matches the saved passcode.
        Api.account.settings.get()
            .then(response => {
                const passcode = response.passcode;
                if (this.encrypted_passcode === null) {
                    if (passcode === null) {
                        this.renderPage();
                    } else {
                        this.$router.push({ name: 'passcode', query: { purpose: 'validate-passcode' } });
                    }
                } else {
                    if (passcode === this.encrypted_passcode) {
                        this.renderPage();
                    } else {
                        this.$router.push({ name: 'settings' });
                    }
                }
            })
            .catch(() => {
                Util.snackbar('Something went wrong. Please try again.');
                this.$router.push({ name: 'settings' });
            });
    },

    methods: {
        validPasscode () {
            // 4-digit passcode
            const passcodePattern = /^\d{4}$/;
            return passcodePattern.test(this.newPasscode);
        },
        savePasscode () {
            if (this.loading) {
                return;
            }
            if (this.validPasscode()) {
                this.loading = true;
                const encryptedPasscode = Crypto.encrypt(this.newPasscode);
                Api.account.settings.update('private_conversations_passcode', 'string', encryptedPasscode)
                    .then(() => {
                        Util.snackbar('Passcode saved successfully');
                        this.$router.push({ name: 'settings' });
                    })
                    .catch(() => {
                        Util.snackbar('Failed to save passcode');
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                Util.snackbar('Please enter a valid 4-digit passcode');
            }
        },
        cancelClicked () {
            this.$router.push({ name: 'settings' });
        },
        renderPage () {
            this.loading = false;
            this.$store.commit('loading', false);
            this.$store.commit('title', this.title);
        }
    }
};
</script>

<style scoped>
    #create-passcode-pane {
        position: relative;
        width: 330px;
        height: 100%;
        margin: 5em auto;
    }
    .loading-center {
        display: flex;
        position: absolute;
        background: #fff;
        height: 100%;
        width: 100%;
        text-align: center;
        vertical-align: middle;
    }
</style>
