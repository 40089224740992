import axios from 'axios';
import store from '@/store/';
import { Api, Url, Crypto, SessionCache } from '@/utils/';

export default class ScheduledMessages {
    static get () {
        const constructedURL = Url.get('scheduled') + Url.getAccountParam();
        const promise = new Promise((resolve, reject) => {
            if (!SessionCache.hasScheduledMessages()) {
                Api.fetchResourcesInChunks(constructedURL, 100, Crypto.decryptScheduledMessage)
                    .then((scheduled_messages) => {
                        SessionCache.putScheduledMessages(scheduled_messages);
                        resolve(scheduled_messages);
                    })
                    .catch(reject);
            } else {
                resolve(SessionCache.getScheduledMessages());
            }
        });
        return promise;
    }

    static delete(id) {
        let constructed_url = Url.get('remove_scheduled') + id + Url.getAccountParam();
        return new Promise((resolve, reject) => {
            axios.post(constructed_url)
                .then(() => {
                    SessionCache.deleteScheduledMessage(id);
                    resolve();
                }).catch((error) => {
                    if (error.response.status === 400 && error.response.data.error === 'no scheduled_messages for id') {
                        SessionCache.deleteScheduledMessage(id);
                        resolve();
                    } else {
                        reject(error);
                    }
                });
        });
    }

    static create(to, message, time, title, repeat) {
        let scheduledMessage = {
            account_id: store.state.account_id,
            device_id: Api.generateId(),
            to: to,
            data: message,
            mime_type: "text/plain",
            title: title.trim().length == 0 ? to : title.trim(),
            timestamp: time,
            repeat: repeat
        };
        let request = {
            ...scheduledMessage,
            to: Crypto.encrypt(scheduledMessage.to),
            data: Crypto.encrypt(scheduledMessage.data),
            mime_type: Crypto.encrypt(scheduledMessage.mime_type),
            title: Crypto.encrypt(scheduledMessage.title)
        }
        request = { ...request, ...Url.getXUserTokenPayload() };

        let constructed_url = Url.get('create_scheduled');

        const promise = new Promise((resolve, reject) => {
            axios.post(constructed_url, request, { 'Content-Type': 'application/json' })
                .then(response => { 
                    SessionCache.addScheduledMessage(scheduledMessage);
                    resolve(response) 
                }).catch(reject);
        });

        return promise;
    }
}
