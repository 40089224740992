<template>
    <div id="login-pane" v-mdl class="mdl-card mdl-shadow--6dp">
        <div class="mdl-card__title mdl-color--primary mdl-color-text--white">
            <h2 class="mdl-card__title-text">
                Pulse SMS
            </h2>
        </div>
        <div class="mdl-card__supporting-text">
            <!-- eslint-disable vue/no-v-html -->
            <p v-if="isLoggingIn" v-html="$t('login.first')"></p>
            <div v-if="error" class="error">
                <p v-if="errorType === 'subscription_expired'">
                    <span>{{ $t('login.subscriptionExpiredError.title') }}</span>
                    <br>
                    <br>
                    <span>{{ $t('login.subscriptionExpiredError.description') }}</span>
                </p>
                <p v-else-if="errorType === 'login'">
                    <span>{{ $t('login.error') }}</span>
                </p>
                <p v-else>
                    {{ this.errorType }}
                </p>
            </div>
            <form>
                <div class="mdl-textfield mdl-js-textfield">
                    <input id="username" v-model="username" class="mdl-textfield__input" type="email" autofocus>
                    <label class="mdl-textfield__label" for="username">{{ $t('login.email') }}</label>
                </div>
                <div class="mdl-textfield mdl-js-textfield">
                    <input id="password" v-model="password" class="mdl-textfield__input" type="password" @keyup.enter="doLogin">
                    <label class="mdl-textfield__label" for="password">{{ $t('login.password') }}</label>
                </div>
            </form>
            <div id="recaptcha-widget-container">
                <vue-recaptcha ref="recaptcha" sitekey="6LczdiEgAAAAAGDnbH6xRalzzZHoC5pXb5gTaBbL" @verify="recaptchaOnSuccess" />
            </div>
            <a v-if="isLoggingIn" href="https://home.pulsesms.app/forgot_password.html" target="_blank">{{ $t('login.forgotpassword') }}</a>
            <br v-if="isLoggingIn">
            <!-- eslint-disable vue/no-v-html -->
            <a v-if="isLoggingIn" href="https://home.pulsesms.app/overview/platform-ios.html" target="_blank" v-html="$t('login.iphone')"></a>
        </div>
        <div class="mdl-card__actions mdl-card--border">
            <button id="login" class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect" @click="doLogin" :disabled="!allowFormSubmit">
                {{ $t('login.login') }}
            </button>
        </div>

        <transition name="loading-fade">
            <div v-if="loading" class="loading-center">
                <spinner />
            </div>
        </transition>
    </div>
</template>

<script>

import { Crypto, Api } from '@/utils/';
import Spinner from '@/components/Spinner.vue';
import VueRecaptcha from 'vue-recaptcha';
import { sjcl } from '@/lib/sjcl.js';
import { hmacSHA1 } from '@/lib/hmacsha1.js';

export default {
    name: 'Login',

    components: {
        Spinner,
        VueRecaptcha
    },

    data () {
        return {
            title: '',
            username: '',
            password: '',
            loading: false,
            error: false,
            errorType: null,
            recaptchaResponse: '',
            purpose: 'login',
            validPurposes: ['login', 'authentication']
        };
    },

    mounted () {
        const purpose = this.$route.query.purpose;
        if (purpose && this.validPurposes.includes(purpose)) {
            this.purpose = purpose;
        }
        if (this.isLoggingIn && this.$store.state.account_id !== '') {
            return this.$router.push({ name: 'conversations-list' });
        }

        this.$store.commit('loading', false);
        this.$store.commit('title', this.title);
    },

    methods: {
        doLogin () {
            if (this.username === '' || this.password === '') {
                return;
            }

            this.error = false;
            this.errorType = null;
            this.loading = true;

            Api.account.login(this.username, this.password, this.recaptchaResponse)
                .then((data) => this.handleData(data.data))
                .catch((data) => this.handleError(data));
        },

        handleData (data) {
            this.error = false;
            this.errorType = null;

            if (this.isLoggingIn) {
                // Create hash
                const derivedKey = sjcl.misc.pbkdf2(this.password, data.salt2, 10000, 256, hmacSHA1);
                const base64Hash = sjcl.codec.base64.fromBits(derivedKey);

                // Save data
                this.$store.commit('account_id', data.account_id);
                this.$store.commit('hash', base64Hash);
                this.$store.commit('salt', data.salt1);

                Crypto.setupAes(); // Setup aes for session

                this.loading = false;

                // Start app
                this.$store.state.msgbus.$emit('start-app');

                this.$router.push({ name: 'conversations-list' });
            } else if (this.isAuthenticating) {
                this.loading = false;
                const redirectTo = this.$route.query.redirectTo;
                if (redirectTo) {
                    if (redirectTo === 'edit-passcode') {
                        this.$router.push({ name: 'edit-passcode', params: { encrypted_passcode: data.passcode } });
                    }
                }
            }
        },

        // https://axios-http.com/docs/handling_errors
        handleError (error) {
            this.password = '';
            this.recaptchaResponse = '';
            this.$refs.recaptcha.reset();
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const response = error.response;
                const errorMessage = response.data.error;
                this.errorType = errorMessage;
                if (response.status === 401 && errorMessage === 'username or password incorrect') {
                    this.errorType = 'login';
                }
            } else if (error.request) {
                // The request was made but no response was received
                this.errorType = this.$t('api.somethingWrong');
            } else {
                // Something happened in setting up the request that triggered an Error
                this.errorType = error.message;
            }
            this.error = true;
            this.loading = false;
        },

        recaptchaOnSuccess (response) {
            this.recaptchaResponse = response;
        }
    },
    computed: {
        allowFormSubmit () {
            return !!(this.recaptchaResponse && this.username && this.password);
        },

        isLoggingIn () {
            return this.purpose === 'login';
        },

        isAuthenticating () {
            return this.purpose === 'authentication';
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    @import "../assets/scss/_vars.scss";

    #login-pane {
        position: relative;
        width: 330px;
        height: 100%;
        margin: 5em auto;
    }

    .loading-center {
        position: absolute;
        margin: 57px auto;
        background: #fff;

        height: 100%;
        width: 100%;

        text-align: center;
        vertical-align: middle;

        .spinner {
            margin: 35% auto;
            translate: scale(2);
        }
    }

    .error {
        color: rgb(255,64,129);
    }

    /* loading-fade transition */
    .loading-fade-enter-active {
        transition-delay: 1s;
        transition: all $anim-time ease;
    }
    .loading-fade-leave-active {
        transition-delay: 1s;
        transition: all $anim-time ease;
    }
    .loading-fade-enter, .loading-fade-leave-to {
        transform: translateY(70%);
        opacity: 0;
    }

    #recaptcha-widget-container {
        padding-bottom: 20px;
    }

</style>
