export default class Platform {

    static isNativeDesktop () {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.indexOf('electron') > 0 && userAgent.indexOf('pulse-sms') > 0;
    }

    static isWindowsDesktop () {
        return Platform.isNativeDesktop() && !!navigator.userAgent.toLowerCase().match(/windows/);
    }

    static isLinuxDesktop () {
        return Platform.isNativeDesktop() && !!navigator.userAgent.toLowerCase().match(/linux/);
    }

    static isMactintoshDesktop () {
        return Platform.isNativeDesktop() && !!navigator.userAgent.toLowerCase().match(/macintosh/);
    }

    static getDesktopPlatform () {
        let platformName;
        if (Platform.isNativeDesktop()) {
            if (Platform.isWindowsDesktop()) {
                platformName = 'windows';
            } else if (Platform.isLinuxDesktop()) {
                platformName = 'linux';
            } else if (Platform.isMactintoshDesktop()) {
                platformName = 'macintosh';
            }
        }
        return platformName;
    }

    static getDesktopVersion () {
        let version;
        if (Platform.isNativeDesktop()) {
            const userAgent = navigator.userAgent.toLowerCase();
            const match = userAgent.match(/pulse-sms\/(v\d\.\d.\d)/);
            if (!!match && match.length > 0) {
                version = match[1];
            }
        }
        return version;
    }

    // BUG. It is broken and doesn't return true.
    static isChromeExtension () {
        return window.chrome && window.chrome.runtime && window.chrome.runtime.id !== undefined;
    }

    static isChromeApp () {
        return navigator.userAgent.toLowerCase().indexOf('chrome-app') > 0;
    }

    static isWebsite () {
        return !Platform.isChromeExtension() && !Platform.isChromeApp() && !Platform.isNativeDesktop();
    }

    static isFirefox () {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    }

    static getPlatformIdentifier () {
        if (Platform.isChromeExtension()) {
            return 1;
        } else if (Platform.isChromeApp()) {
            return 2;
        } else if (Platform.isNativeDesktop()) {
            return 3;
        } else {
            return 0; // fallback to just the web browser
        }
    }

}
