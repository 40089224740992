<template>
    <div>
        <div class="click-item mdl-js-button mdl-js-ripple-effect" @click="menu.toggle()">
            <div class="mdl-color-text--grey-900">
                {{ response }}
            </div>
            <div class="mdl-color-text--grey-600">
                <template v-if="replyTypeDriving || replyTypeVacation">
                    Type: <span class="reply-type">{{ reply_type }}</span>
                </template>
                <template v-else>
                    <span class="reply-type">{{ reply_type }}</span>: {{ pattern }}
                </template>
            </div>
        </div>
        <ul id="reply-menu"
            class="mdl-menu mdl-js-menu mdl-js-ripple-effect mdl-menu--unaligned" :data-mdl-for="id"
        >
            <li class="mdl-menu__item" @click="deleteReply">
                Delete
            </li>
        </ul>
    </div>
</template>

<script>

import store from '@/store/';
import { Util, Api } from '@/utils';
import { componentHandler } from '@/lib/material.js';

export default {
    name: 'AutoReplyItem',
    props: [ 'replyData' ],

    data () {
        return {
            id: this.replyData.device_id,
            response: this.replyData.response,
            reply_type: this.replyData.reply_type,
            pattern: this.replyData.pattern,

            menu: null,
        };
    },

    mounted () {
        let menu_el = this.$el.querySelector("#reply-menu");
        componentHandler.upgradeElement(menu_el);

        this.menu = menu_el.MaterialMenu;
    },

    methods: {
        deleteReply () {
            Api.autoReplies.delete(this.id)
                .then(() => {
                    Util.snackbar("Deleted auto reply: " + this.response);
                })
                .catch(() => {
                    Util.snackbar("Failed to delete auto reply: " + this.response);
                });
        }
    },

    computed: {
        replyTypeDriving() {
            return this.reply_type === 'driving';
        },

        replyTypeVacation() {
            return this.reply_type === 'vacation';
        },

        replyTypeContact() {
            return this.reply_type === 'contact';
        },

        replyTypeKeyword() {
            return this.reply_type === 'keyword';
        }
    }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    @import "../../../assets/scss/_vars.scss";

    .item, .click-item {
        position: relative;
        width: 100%;
        padding: 12px;
        line-height: 18px;

        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

    .item:hover, .click-item:hover {
        background: #E0E0E0;
    }

    .click-item:hover {
        cursor: pointer;
    }

    body.dark {
        .item:hover, .click-item:hover {
            background: #202020;
        }
    }

    .reply-type {
        text-transform: capitalize;
    }

</style>
