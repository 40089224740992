<template>
    <div id="create-auto-reply" v-mdl class="mdl-card mdl-shadow--6dp">
        <div class="mdl-card__title">
            <h2 class="mdl-card__title-text">
                {{ $t('settings.auto_reply.create') }}
            </h2>
        </div>

        <div class="mdl-card__supporting-text">
            <ContactEntryBar v-show="typeContact" :on-contact-list-changed="onContactListChanged" :max-selectable-contacts="1" />
            <div v-show="typeKeyword" class="mdl-textfield mdl-js-textfield">
                <input id="pattern" v-model="pattern" class="mdl-textfield__input" />
                <label class="mdl-textfield__label" for="pattern">{{ $t('settings.auto_reply.keyword') }}</label>
            </div>
            <div class="mdl-textfield mdl-js-textfield">
                <input id="response" v-model="response" class="mdl-textfield__input" />
                <label class="mdl-textfield__label" for="response">{{ $t('settings.auto_reply.response') }}</label>
            </div>
        </div>

        <div class="mdl-card__actions mdl-card--border">
            <button id="create" class="mdl-button mdl-js-button mdl-js-ripple-effect" @click="create">
                {{ $t('dialog.create') }}
            </button>
            <button id="cancel" class="mdl-button mdl-js-button mdl-js-ripple-effect" @click="cancel">
                {{ $t('dialog.cancel') }}
            </button>
        </div>

        <transition name="loading-fade">
            <div v-if="loading" class="loading-center">
                <spinner />
            </div>
        </transition>
    </div>
</template>

<script>
import { Api, Util } from '@/utils/';
import Spinner from '@/components/Spinner.vue';
import ContactEntryBar from '@/components/Compose/ContactEntryBar.vue';

export default {
    name: 'CreateAutoReply',
    components: {
        Spinner,
        ContactEntryBar
    },
    data () {
        return {
            pattern: "",
            response: "",
            type: "contact",
            types: ["contact", "keyword"],
            loading: false,
        };
    },
    mounted () {
        this.setType();
        this.$store.commit("loading", false);
        this.$store.commit('title', "Create Auto Reply");
    },
    computed: {
        typeKeyword () {
            return this.type === "keyword";
        },
        typeContact () {
            return this.type === "contact";
        }
    },
    methods: {
        setType () {
            const type = this.$route.query.type;
            if (this.types.includes(type)) {
                this.type = type;
            }
        },
        goToAutoReplies () {
            this.$router.push({ name: "auto-replies" });
        },
        isValidInput () {
            let valid = true;
            if (!this.pattern) {
                valid = false;
                if (this.typeKeyword) {
                    Util.snackbar('Keyword is required');
                } else if (this.typeContact) {
                    Util.snackbar('Contact is required');
                }
            }
            if (!this.response) {
                valid = false;
                Util.snackbar('Response is required');
            }
            return valid;
        },
        create () {
            if (!this.isValidInput()) {
                return;
            }
            this.loading = true;
            Api.autoReplies.create(this.type, this.pattern, this.response)
                .then(() => {
                    Util.snackbar('Auto reply created');
                    this.goToAutoReplies();
                })
                .catch((error) => {
                    Util.snackbar('Failed to create auto reply');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cancel () {
            this.goToAutoReplies();
        },
        onContactListChanged (contacts) {
            if (contacts.length === 0) {
                this.pattern = "";
                return;
            }
            this.pattern = contacts[0].phone;
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/_vars.scss";

    #create-auto-reply {
        position: relative;
        width: 330px;
        height: 100%;
        margin: 5em auto;
    }

    .loading-center {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: 57px auto 0;
        background: #fff;
        height: calc(100% - 57px);
        width: 100%;
    }

    // Contact Entry Bar
    ::v-deep .ui.multiple.search.dropdown > input.search {
        // 1.21428571em is the line-height of the input
        // .45238095em is the padding-top and padding-bottom of the input
        margin-bottom: calc(1.21428571em + .45238095em);
    }

</style>

