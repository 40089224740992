import axios from 'axios';

import store from '@/store/';

import {
    Account,
    AutoReply,
    Blacklist,
    Contacts,
    Conversations,
    Devices,
    Drafts,
    Folders,
    Messages,
    ScheduledMessages,
    Stream,
    Templates
} from '@/utils/api/';

export default class Api {
    static Stream = Stream

    static account = Account
    static autoReplies = AutoReply
    static blacklist = Blacklist
    static contacts = Contacts
    static conversations = Conversations
    static devices = Devices
    static drafts = Drafts
    static folders = Folders
    static messages = Messages
    static scheduledMessages = ScheduledMessages
    static templates = Templates

    static generateId () {
        const min = 1;
        const max = 922337203685477;

        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static rejectHandler (e, callback = null) {
        if (e.response && e.response.status === 401) {
            return store.state.msgbus.$emit('logout-btn');
        }

        if (callback) {
            return callback(e);
        }
    }

    // purpose: to fetch the resources in chunks using pagination.
    // return type: a promise which resolves on decrypted resources array.
    static fetchResourcesInChunks (url, chunkSize, resourceDecrypter, maximumObjects = null) {
        const promise = new Promise((resolve, reject) => {
            const resources = [];
            function queryResources () {
                axios.get(url + '&limit=' + chunkSize + '&offset=' + resources.length).then((response) => {
                    response = response.data;
                    // decrypting the response
                    for (let i = 0; i < response.length; i++) {
                        let resource = response[i];
                        const descryptedResource = resourceDecrypter(response[i]);
                        if (descryptedResource != null) {
                            resource = descryptedResource;
                        }
                        if (resource != null) {
                            resources.push(resource);
                        }
                    }
                    if (response.length === chunkSize && (!maximumObjects || resources.length < maximumObjects)) {
                        queryResources();
                    } else {
                        resolve(resources);
                    }
                }).catch(response => Api.rejectHandler(response, reject));
            }
            queryResources();
        });
        return promise;
    }

    // purpose: to fetch resources in a single call without pagination
    // return type: a promise which resolves on decrypted resources array.
    static fetchResources (url, resourceDecrypter) {
        const promise = new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    response = response.data;
                    // decrypting the response
                    for (let i = 0; i < response.length; i++) {
                        const resource = resourceDecrypter(response[i]);
                        if (resource != null) {
                            response[i] = resource;
                        }
                    }
                    resolve(response); // Resolve response
                })
                .catch(response => Api.rejectHandler(response, reject));
        });
        return promise;
    }
}
