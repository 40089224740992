<template>
    <div id="account-list" v-mdl class="page-content">
        <img src="../assets/images/pulse-sms-plus-logo-light-bg.png" alt="Logo" class="logo light-bg-logo">
        <img src="../assets/images/pulse-sms-plus-logo-dark-bg.png" alt="Logo" class="logo dark-bg-logo">
        <h4>Support</h4>
        <div class="click-item" :href="urls.help" @click.stop.prevent="routeTo(urls.help)">
            <div class="mdl-color-text--grey-900">
                FAQ
            </div>
            <div class="mdl-color-text--grey-600">
                Pulse SMS FAQ
            </div>
        </div>
        <div class="click-item" :href="urls.email" @click.stop.prevent="routeTo(urls.email)">
            <div class="mdl-color-text--grey-900">
                Support Email
            </div>
            <div class="mdl-color-text--grey-600">
                support@pulsesms.app
            </div>
        </div>

        <h4>Information</h4>
        <div class="click-item mdl-color-text--grey-900" :href="urls.overview" @click.stop.prevent="routeTo(urls.overview)">
            Features & Supported Platforms
        </div>
        <div class="click-item mdl-color-text--grey-900" @click="routeTo(urls.shortcutKeys)">
            Web Shortcut Keys
        </div>
        <div class="click-item mdl-color-text--grey-900" :href="urls.privacy" @click.stop.prevent="routeTo(urls.privacy)">
            Privacy Policy
        </div>
        <div class="click-item mdl-color-text--grey-900" :href="urls.tos" @click.stop.prevent="routeTo(urls.tos)">
            Terms of Service
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelpFeedback',
    data () {
        return {
            title: 'Support',
            loading: false,
            urls: {
                help: 'https://pulsesms.zendesk.com/hc/en-us',
                overview: 'https://home.pulsesms.app/overview/',
                twitter: 'https://twitter.com/pulsesms',
                email: 'mailto:support@pulsesms.app?subject=Pulse%20SMS',
                privacy: 'https://home.pulsesms.app/privacy.html',
                tos: 'https://home.pulsesms.app/tos.html',
                shortcutKeys: 'https://pulsesms.zendesk.com/hc/en-us/articles/13878244228635-Pulse-SMS-Are-there-any-shortcut-keys-for-common-actions-on-the-web-desktop-app-'
            }
        };
    },

    mounted () {
        this.$store.commit('title', this.title);
    },

    methods: {
        routeTo (route) {
            window.open(route, '_blank');
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    @import "../assets/scss/_vars.scss";

    .logo {
        width: 185px;
    }

    .dark-bg-logo {
        display: none;
    }

    .item, .click-item {
        position: relative;
        width: 100%;
        padding: 12px;
        line-height: 18px;
    }

    .item:hover, .click-item:hover {
         background: #E0E0E0;
    }

    .click-item:hover {
        cursor: pointer;
    }

    body.dark {
        .item:hover, .click-item:hover {
            background: #202020;
        }
        .light-bg-logo {
            display: none;
        }
        .dark-bg-logo {
            display: inline-block;
        }
    }

</style>
